import Vue from 'vue'
import App from './App.vue'
// 引入ElementUI组件库
import ElementUI from 'element-ui';
//引入ElementUI的所有样式
import 'element-ui/lib/theme-chalk/index.css';
import 'highlight.js/styles/devibeans.css'

import { marked } from 'marked'

import {numFormat,chineseNumber} from '@/utils/utils'




Vue.config.productionTip = false

//使用ElementUI
Vue.use(ElementUI)

Vue.prototype.marked = marked
Vue.prototype.numFormat = numFormat
Vue.prototype.chineseNumber = chineseNumber


new Vue({
  render: h => h(App),
}).$mount('#app')


