<template>
  <div class="box" id="chat-box">
    <aside>
      <div class="aside-top">
        <div style="margin-bottom: 25px;">
          <img src="@/assets//group-manage.svg" alt="" style="width: 16px;">
          <el-select v-model="companyValue" placeholder="请选择您的公司" size="small" class="selectCompany" @change="companyChange" 
            popper-class="accountsDropDownBox">
            <el-option v-for="item in userCompanyList" :key="item.companyId" :label="item.companyName" :disabled="companyDisabled"
              :value="item.companyId">
            </el-option>
          </el-select>
        </div>
        <div class="aside-top-btn" @click="initiateQuestion"
          :style="{backgroundColor:flag ? '' : '#0076f6',color:flag ? '' : '#FFF'}">
          <img src="@/assets//logo.svg" v-if="flag" style="width: 34px;height: 32px;">
          <img src="@/assets//copyLogo.svg" v-else style="width: 34px;height: 32px;">
          <div style="display: inline-block;transform: translateY(-9px)">
            呼叫小白
          </div>
        </div>
      </div>
      <div style="text-align: center;color: #999999;margin-bottom: 10px;">
        —————&nbsp;&nbsp;&nbsp;&nbsp;历史聊天记录&nbsp;&nbsp;&nbsp;&nbsp;—————
      </div>
      <div class="aside-title">
        <template v-if="this.QuestionList.length > 0">
          <div class="aside-among" v-for="(item,index) in this.QuestionList">
            <!-- :style="{backgroundColor:item.titleId == this.titleId ? '#0076f6' : ''}" -->
            <div>
              <button class="aside-among-btn" @click.stop="lookQuestion(item,$event)"
                :style="{background:item.titleId == titleId ? '#0076f6' : '',color:item.titleId == titleId ? '#FFFFFF' : ''}">
                <i class="el-icon-chat-line-round"
                  style="width: 15px;height: 15px;float:left;margin: 5px 10px 5px 15px;"></i>
                <div v-if="!item.isEdit" class="btn-text" :title="item.title">
                  {{item.title}}
                </div>
                <input v-if="item.isEdit" ref="inputQuestion" class="input-question" type="text" :value="item.title"
                  @click.stop>
                </input>
              </button>
            </div>
            <div class="title-btn">
              <!--              修改或者删除确认按钮-->
              <el-button v-if="item.isEdit || item.isDel" class="icon" type="text"
                :style="{color:item.titleId == titleId ? '#FFF' : '#2d8cf0'}"
                icon="el-icon-check" @click.stop="doIt(item,index)"></el-button>
              <!--              取消操作按钮-->
              <el-button v-if="item.isEdit || item.isDel" class="icon" type="text"
                :style="{color:item.titleId == titleId ? '#FFF' : '#2d8cf0'}"
                icon="el-icon-close" @click.stop="item.isEdit = false; item.isDel = false"></el-button>
              <!--              修改按钮-->
              <el-button v-if="!item.isEdit && !item.isDel" class="icon" type="text"
                :style="{color:item.titleId == titleId ? '#FFF' : '#2d8cf0'}"
                icon="el-icon-edit-outline" @click.stop="edit(item, true, $event,index)"></el-button>
              <!--              删除按钮-->
              <el-button v-if="!item.isEdit && !item.isDel" class="icon" type="text"
                :style="{color:item.titleId == titleId ? '#FFF' : '#2d8cf0'}"
                icon="el-icon-delete" @click.stop="del(item, true)"></el-button>
            </div>
          </div>
        </template>
      </div>
      <div class="aside-bottom">
        <div style="margin:20px 0 0 26px">
          剩余聊天次数：{{this.residueTimes}}次
        </div>
        <div style="margin:20px 0 0 26px">
          去<span @click="recharge" style="color: #0076f6;cursor: pointer;">充值</span>，增加聊天次数
        </div>
      </div>
    </aside>
    <article>
      <div class="article-top">
        <div class="article-left" style="clear: both">
          <div class="name-class" style="background-color: #FFFFFF;">
            <img src="@/assets//logo.svg" alt="" style="width: 34px;height: 32px;background-color: #FFF;">
          </div>
          <div class="text-class" style="background-color: #FFF;" ref="markdown">
            您好，我能回答你的所有问题，快来和我聊天吧！
          </div>
        </div>
        <div v-for="item in this.List">
          <div class="article-right">
            <div class="name-class">
              {{!!userName ? userName.slice(-2) : ''}}
            </div>
            <div class="text-class" style="white-space: pre-wrap;" v-html="item.userContent" ref="userContent">
            </div>
          </div>
          <div class="article-left" style="clear: both">
            <div class="name-class" style="background-color: #FFFFFF;">
              <img src="@/assets/logo.svg" alt="" style="width: 34px;height: 32px;background-color: #FFF;">
            </div>
            <div class="text-class" style="background-color: #FFF;" ref="markdown" v-html="item.botContent"> </div>
            <!-- <div class="text-class" style="background-color: #FFF;" ref="markdown" >
                <div style="white-space: pre-wrap;">{{item.botContent}}</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="article-bottom">
        <textarea id="text" @input="handleInput($event)" @keydown="prohibit" rows="1" placeholder="请输入消息"></textarea>
        <span>
          Enter发送，Ctrl+Enter换行
        </span>
        <button :style="{background: this.finished && this.isTextarea && this.residueTimes > 0 ? '' : '#CCC'}"
          class="article-bottom-btn" @click="submit">发送</button>
      </div>
    </article>
    <!-- 聊天充值 -->
    <template>
      <el-dialog title="聊天充值" :visible="dialogVisible" width="488px" :before-close="handleCanceledit" :close-on-click-modal="false"
        @close="handleClose">
        <el-row>
          <el-col :span="24">{{this.companyValue == -1 ? '购买人：' : '购买单位：' }}{{this.companyValue == -1 ? this.userName : this.companyName }}</el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="24" style="color: #999;">剩余聊天次数：{{this.residueTimes}}次</el-col>
        </el-row>
        <el-row style="margin-top:20px" v-if="companyValue != -1">
          <el-col :span="24">订购套餐：</el-col>
          <el-col :span="24" style="margin-top: 10px;">
            <el-button-group v-for="(item,index) in mealList.chatCommodityPackagesList" :key="item.commodityPackageId">
              <el-button @click="changeMeal(item)"
                :style="{width:'95px',height:'70px',backgroundColor:item.commodityPackageId != curMeal ? '' : '#2D8CF0',borderRadius:'8px',marginLeft:index == 0 ? '' : '16px',padding:'0 2px'}">
                <div
                  :style="{fontSize: '12px',transform: 'scale(0.8)',color:item.commodityPackageId != curMeal ? '#666' : '#FFF'}">
                  <!-- {{item.number}}次聊天套餐 -->{{item.commodityPackageName}}
                </div>
                <div
                  :style="{fontSize: '19px',marginTop: '15px',color:item.commodityPackageId != curMeal ? '#333' : '#FFF'}">
                  ￥{{item.packageAmount}}元
                </div>
              </el-button>
            </el-button-group>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="24">
            选择次数： <el-input-number v-model="number" size="mini" controls-position="right" :min="1" :max="99999"
              @change="handleChange" style="width: 100px;" @blur="handleBlur" :precision="0"></el-input-number>
            *{{this.mealList.commodityMultiplier}}次
            <span style="color: #999;margin-left: 20px;">付款后新增{{newNumber}}次聊天次数</span>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="24">支付金额：
            <span style="font-size: 24px;color: #DC001B;margin-right:10px;">￥{{this.numFormat(money,2)}}</span>
            <span style="color: #DC001B;margin-right: 29px;">人民币<span style="margin-left: 15px;">{{this.chineseNumber(money)}}</span></span>
            <span style='white-space: nowrap;'>
              (￥{{mealList.commodityUnitPrice}}/次 *
              {{newNumber}}次)
            </span>
          </el-col>
        </el-row>
        <el-row style="margin-top:43px">
          <el-col :span="24">
            <el-checkbox v-model="checked">
              我已阅读并同意 <a style="color: #2D8CF0;text-decoration: none;" href="https://terms.baiwan.com.cn/purchaseDescription.html" target="_blank">《购买聊天次数说明条款》</a>
            </el-checkbox>
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button type="primary" @click="rechargeSubmit" size="mini" :disabled="!checked"
            :style="{backgroundColor:checked ? '' : '#999',border:'none'}">确 定</el-button>
        </div>
      </el-dialog>
    </template>
    <!-- 订单详情 -->
    <template>
      <el-dialog title="订单详情" :visible="visible" width="518px" :before-close="handleEdit" style="color: #333;" :close-on-click-modal="false"
        @close="detailsClose">
        <el-row>
          <el-col :span="24">
            <div style="display: inline-block;width: 2px;height: 18px;color: #2D8CF0;margin-right: 6px;">|</div> 订单详情
          </el-col>
        </el-row>
        <el-row style="margin: 20px 0 0 12px;">
          <el-col :span="12">{{this.companyValue == -1 ? '购买人：' : '购买单位：' }}{{ this.companyValue == -1 ? this.userName : this.companyName }}</el-col>
          <el-col :span="12">商品名称：聊天次数{{newNumber}}次</el-col>
        </el-row>
        <el-row style="margin: 20px 0 0 12px;">
          <el-col :span="12">下单时间：{{this.localCurrentTime}}</el-col> 
          <el-col :span="12">订单金额：<span style="color: #DC0620;">{{this.numFormat(money,2)}}元</span></el-col>
        </el-row>
        <el-row style="margin-top: 20px;">
          <el-col :span="24">
            <div style="display: inline-block;width: 2px;height: 18px;color: #2D8CF0;margin-right: 6px;">|</div>选择支付方式
          </el-col>
        </el-row>
        <el-row style="margin: 20px 0 0 12px;">
          <el-col :span="17"><el-radio v-model="radio" label="0"><img src="@/assets/vx.svg" alt=""
                style="width: 26px;height: 26px;margin: 0 10px;transform: translateY(7px)">微信</el-radio>
          </el-col>
          <el-col :span="7" style="margin-top: 10px">
            支付<span style="color: #DC0620;">{{this.numFormat(money,2)}}</span>元
          </el-col>
        </el-row>
        <el-row style="margin: 20px 0 0 12px;">
          <el-col :span="17"><el-radio v-model="radio" label="1"><img src="@/assets/zfb.svg" alt=""
                style="width: 26px;height: 26px;margin: 0 10px;transform: translateY(7px)">支付宝</el-radio>
          </el-col>
          <el-col :span="7" style="margin-top: 10px">
            支付<span style="color: #DC0620;">{{this.numFormat(money,2)}}</span>元
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button type="primary" @click="paySubmit" size="mini">确 定</el-button>
        </div>
      </el-dialog>
    </template>
    <!-- 二维码 -->
    <template>
      <el-dialog :title="title" :visible="codeVisible" width="430px" :before-close="codeEdit" style="color: #333;" custom-class="code-dialog" :close-on-click-modal="false"
        @close="codeClose">
        
        <span class="payment-dialog-QRCode-box">
          <img src="@/assets/refresh.png"  alt="" v-if="QRCodeMask" style="position: absolute;top: 37%;left: 37%;z-index: 999;" @click="regenerationCode">
          <div id="qrcode" style="width: 172px;height: 172px;margin:0 auto">
          </div>
          <span class="payment-dialog-QRCode-box-mask" v-if="QRCodeMask">
          </span>
        </span>
        <div style="text-align: center;margin-top: 16px;color: #333;">
          {{radio == '0' ? '微信扫描二维码完成支付' : '支付宝二维码完成支付'}}
        </div>
      </el-dialog>
    </template>
    <!-- 支付成功 -->
    <template>
      <el-dialog :visible="successVisible" width="430px" :before-close="successEdit" custom-class="sus-dialog" :close-on-click-modal="false"  @close="successClose" style="color: #333;">
        <div style="display: flex;align-items: center;justify-content:center;">
            <img src="@/assets/Slice.png" alt="" style="margin-right: 14px;">
          <div>
            <div style="font-weight: bold;font-size: 20px;color: #333333;margin-bottom: 5px;">支付成功 !</div>
            <div style="font-size: 16px;">感谢您的购买</div>
          </div>
        </div>
        <el-row style="margin-top: 47px;">
          <el-col :span="24"><span style="font-weight: bold;">商品名称：</span>聊天次数{{newNumber}}次</el-col>
        </el-row>
        <el-row style="margin-top: 18px;">
          <el-col :span="24"><span style="font-weight: bold;">{{this.companyValue == -1 ? '购买人：' : '购买单位：' }}</span>{{this.companyValue == -1 ? this.userName : this.companyName}}</el-col>
        </el-row>
        <el-row style="margin-top: 18px;">
          <el-col :span="24"><span style="font-weight: bold;">订单编号：</span>{{this.orderNumber}}</el-col>
        </el-row>
        <el-row style="margin-top: 18px;">
          <el-col :span="24"><span style="font-weight: bold;">下单时间：</span>{{this.localCurrentTime}}</el-col>
        </el-row>
        <el-row style="margin-top: 18px;">
          <el-col :span="24"><span style="font-weight: bold;">支付方式：</span>{{this.radio == '0' ? '微信支付' : '支付宝支付' }}</el-col>
        </el-row>
        <el-row style="margin-top: 18px;">
          <el-col :span="24"><span style="font-weight: bold;">订单金额：</span>￥{{this.numFormat(money,2)}}</el-col>
        </el-row>
        <div slot="footer" class="dialog-footer" style="text-align: center;">
          <el-button type="primary" @click="successVisible = false;visible = false;dialogVisible = false;codeVisible = false;" size="mini">知道了</el-button>
        </div>
      </el-dialog>
    </template>
    <template>
      <el-dialog :visible="failVisible" width="430px" custom-class="sus-dialog" :close-on-click-modal="false"  @close="successClose" style="color: #333;">
        <div style="display: flex;align-items: center;justify-content:center;">
            <img src="@/assets/fail.png" alt="" style="margin-right: 14px;">
          <div>
            <div style="font-weight: bold;font-size: 20px;color: #333333;margin-bottom: 5px;">支付失败 !</div>
            <div style="font-size: 16px;">请重新支付</div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center;"> 
          <el-button type="primary" @click="rePayment" size="mini">重新支付</el-button>
          <el-button @click="failVisible = false;visible = false;dialogVisible = false;codeVisible = false;" size="mini" style="margin-left: 60px;">取消</el-button>
        </div>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import hljs from "highlight.js"
import BigNumber from 'bignumber.js'
import QRCode  from 'qrcode'
export default {
  props: {},
  data() {
    return {
      // 剩余提问次数
      residueTimes: 0,
      userName: "",
      companyName: "",
      // 头像切换
      flag: true,
      // 是否能发送提问
      finished: true,
      // 当前选中的title
      curTitleId: null,
      // 是否有文字输入
      isTextarea: false,
      // prefixUrl: 'http://192.168.1.103:8081',
      // prefixUrl: 'https://chat.baiwan.com.cn/prod-api',
      prefixUrl: "https://chat-stage.baijcn.com/stage-api",
      token: "",
      // 问题集合
      QuestionList: [],
      userId: "",
      titleId: null,
      socket: null,
      List: [],
      index: 0, // 当前已经截取的字符串长度
      arr: "",
      // 时间戳
      localCurrentTime: "",
      // 当前选中的公司
      companyValue: "",
      // 公司列表
      userCompanyList: [],
      // 当前是否处于断开状态
      isBreak: false,
      // 聊天充值弹窗
      dialogVisible: false,
      // 要充值的次数
      number: 1,
      // 聊天套餐价格
      mealList: [],
      // 当前选择的套餐
      curMeal: "",
      // 是否勾选已查看购买说明
      checked: false,
      // 订单详情
      visible: false,
      // 支付方式
      radio: "0",
      // 二维码弹窗
      title: "",
      codeVisible: false,
      // 订单
      orderNumber: "",
      // 定时器
      interval: "",
      // 轮询次数
      count: "",
      // 支付成功
      successVisible: false,
      failVisible: false,
      // 二维码失效状态
      QRCodeMask: false,
      commodityPackageName: "",
      // 是否能切换公司
      companyDisabled: false,
    };
  },
  created() {
    this.userId = location.href.split('=')[1]
    this.token = location.href.split('=')[2]
    this.userName = decodeURIComponent(location.href.split('=')[3] || "")
    this.userCompanyList = JSON.parse(decodeURIComponent(location.href.split('=')[4]))
    this.userCompanyList.push({ companyId: '-1', companyName: '百家万户个人版' })
    this.companyValue = location.href.split('=')[5] + ''
    // this.userId = 1000068;
    // this.token = "5dc6c94b-bb51-4c97-84ce-690e9fdd7bf9";
    // this.userName = "aaaaaaaabb";
    // this.userCompanyList = [
    //   {
    //     companyId: "147",
    //     companyName: "asda",
    //   },
    //   {
    //     companyId: "87",
    //     companyName: "CCCC",
    //   },
    //   {
    //     companyId: "148",
    //     companyName: "dsaaaaaaa",
    //   },
    //   {
    //     companyId: "-1",
    //     companyName: "百家万户个人版",
    //   },
    // ];
    // this.companyValue = 87 + "";
    this.getQuestionList();
    this.getUserVip();
  },
  mounted() {
    this.userCompanyList.forEach((item) => {
      if (this.companyValue == item.companyId) {
        this.companyName = item.companyName;
      }
    });
    this.initSocket();
  },
  components: {},
  computed: {
    newNumber() {
      if (this.mealList.length == 0) {
        return 0;
      }
      return new BigNumber(this.number)
        .times(this.mealList.commodityMultiplier)
        .toNumber();
    },
    money() {
      if (this.mealList.length == 0) {
        return 0.0;
      }
      
      if (!this.curMeal) {
        return new BigNumber(this.number)
          .times(this.mealList.commodityMultiplier)
          .times(this.mealList.commodityUnitPrice)
          .toNumber()
          .toFixed(2)
      } else {
        let packageAmount = "";
        this.mealList.chatCommodityPackagesList.forEach((item) => {
          if (this.curMeal == item.commodityPackageId) {
            packageAmount = item.packageAmount;
          }
        });
        return packageAmount.toFixed(2)
      }
    },
  },
  watch: {},
  methods: {
    // 初始化
    initSocket() {
      this.socket = new WebSocket(
        "wss://chat-stage.baijcn.com/websocket/ws/chat"
      );
      // this.socket = new WebSocket("ws://192.168.1.103:8081/ws/chat")
      this.socket.onopen = (e) => {
        this.finished = true;
        this.isBreak = false;
        this.companyDisabled = false;
        // this.$message.success("连接成功!")
      };
      this.socket.onmessage = (e) => {
        let data = JSON.parse(e.data);
        if (data.code == 200) {
          this.finished = data.data.finished;
          // const htmlContent = marked.parse(data.data.resMessage)
          if (!!data.data.resMessage) {
            const htmlContent = data.data.resMessage;
            requestAnimationFrame(this.write.bind(window, htmlContent));
          }
          this.titleId = data.data.titleId;
          this.index = 0;
          if (!!data.data.refreshTitle) {
            this.getQuestionList();
          }
          if (data.data.finished == true) {
            this.companyDisabled = false;
            this.getUserVip();
          }
        } else {
          this.finished = true;
        }
      };
      (this.socket.onclose = (e) => {
        this.finished = false;
        this.isBreak = true;
        setTimeout(() => {
          this.initSocket();
        }, 5000);
      }),
        (this.socket.onerror = (e) => {
          this.isBreak = false;
          this.finished = true;
        });
    },
    write(htmlContent) {
      const div = this.$refs.markdown[this.$refs.markdown.length - 1];
      this.arr += htmlContent;
      div.innerHTML = this.marked.parse(this.arr);
      // 滚动条每次都滚动到最底部
      // const container = document.querySelector('.article-top')
      // container.scrollTop = container.scrollHeight - container.clientHeight

      // div.innerHTML = htmlContent.slice(0, this.index++)
      // if (this.index <= htmlContent.length) {
      //   requestAnimationFrame(this.write.bind(window,htmlContent))
      // }
      // 渲染代码块
      hljs.highlightAll();
    },
    // 查询用户VIP状态接口
    getUserVip() {
      if (this.companyValue != -1) {
        axios
          .get(this.prefixUrl + "/chat/companyVip/selectCompanyVip", {
            params: {
              userId: this.userId,
              companyId: this.companyValue,
            },
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((res) => {
            // this.residueTimes = res.data.data.freeTimes + res.data.data.vipTimes
            this.residueTimes = res.data.data;
          })
          .catch((err) => {
            console.error("API调用异常：" + err);
          });
      } else {
        axios
          .get(this.prefixUrl + "/chat/chatUserVip/" + this.userId, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((res) => {
            this.residueTimes =
              res.data.data.freeTimes + res.data.data.vipDayTimes;
          })
          .catch((err) => {
            console.error("API调用异常：" + err);
          });
      }
    },
    // 查询用户标题列
    getQuestionList() {
      axios
        .get(this.prefixUrl + "/title/title/list", {
          params: {
            userId: this.userId,
            companyId: this.companyValue != -1 ? this.companyValue : "",
          },
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.QuestionList = res.data;
        })
        .catch((err) => {
          console.error("API调用异常：" + err);
        });
    },
    // 发起提问
    initiateQuestion() {
      if (!this.finished && !this.isBreak) {
        this.$message("小白正在努力回答中，请不要离开");
        return;
      }
      this.List = [];
      this.titleId = null;
      setTimeout(() => {
        this.flag = false;
      });
      setTimeout(() => {
        this.flag = true;
      }, 150);
    },
    // 查看提问
    lookQuestion(item, event) {
      if (!this.finished && !this.isBreak) {
        this.$message("小白正在努力回答中，请不要离开");
        return;
      }
      if (event.detail == 1) {
        document.getElementById("text").value = "";
        this.isTextarea = false;
        this.flag = true;
        this.titleId = item.titleId;
        axios
          .get(this.prefixUrl + "/answer/chatHis/list", {
            params: {
              userId: this.userId,
              titleId: item.titleId,
            },
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((res) => {
            this.List = res.data;
            this.$nextTick(() => {
              res.data.forEach((item, index) => {
                this.$refs.markdown.forEach((val, index_) => {
                  if (index == index_) {
                    val.innerHTML = this.marked.parse(item.botContent);
                    !!hljs && hljs.highlightAll();
                  }
                });
              });
            });
          })
          .catch((err) => {
            console.error("API调用异常：" + err);
          });
      }
    },
    // 修改提问标题
    blurQuestion(item, index) {
      if (!this.finished && !this.isBreak) {
        this.$message("小白正在努力回答中，请不要离开");
        return;
      }
      item.title = this.$refs.inputQuestion[0].value;
      axios({
        url: this.prefixUrl + "/title/title",
        method: "put",
        data: JSON.stringify(item),
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("修改成功!");
            this.getQuestionList();
          } else {
            this.$message.error("修改失败!");
          }
        })
        .catch((err) => {
          console.error("API调用异常：" + err);
        });
    },
    // 删除提问
    delQuestion(item) {
      if (!this.finished && !this.isBreak) {
        this.$message("小白正在努力回答中，请不要离开");
        return;
      }
      axios
        .delete(
          this.prefixUrl +
            `/title/title/remove?titleId=${item.titleId}&userId=${item.userId}`,
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.code) {
            this.$message.success("删除成功");
            if (this.titleId == item.titleId) {
              this.List = [];
              this.titleId = null;
            }
            this.getQuestionList();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((err) => {
          console.error("API调用异常：" + err);
        });
    },
    // 是否可以发送
    handleInput(e) {
      this.isTextarea = !!document.getElementById("text").value.trim();
    },
    // 发送
    submit() {
      if (this.finished && this.isTextarea && this.residueTimes > 0) {
        let reqMessage = document.getElementById("text").value;
        let obj = {
          userId: this.userId,
          titleId: this.titleId,
          companyId: this.companyValue != -1 ? this.companyValue : "",
          reqMessage: reqMessage,
        };
        this.socket.send(JSON.stringify(obj));
        let reqObj = {
          botContent: "",
          userContent: reqMessage,
        };
        this.List.push(reqObj);
        this.$nextTick(() => {
          const div = this.$refs.markdown[this.$refs.markdown.length - 1];
          div.innerHTML = `<div style="width: 110px;">
                <i class="el-icon-loading"></i>
                正在生成 
                <i class="el-icon-more"></i>
                </div>
                `;
          // 自动定位到底部
          div.scrollIntoView();
        });
        this.isTextarea = false;
        this.finished = false;
        this.arr = "";
        this.companyDisabled = true;
        document.getElementById("text").value = "";
      }
    },
    // 键盘禁止回车的默认换行
    prohibit(event) {
      var altKey = event.ctrlKey || event.metaKey;
      if (event.keyCode == 13 && altKey) {
        var newDope = document.getElementById("text").value + "\n";
        document.getElementById("text").value = newDope;
      } else if (event.keyCode == 13) {
        this.submit();
        event.preventDefault(); //禁止回车的默认换行
      }
    },
    edit(item, bool, $event, index) {
      this.QuestionList.forEach((item) => {
        this.$set(item, "isEdit", false);
        this.$set(item, "isDel", false);
      });
      this.$set(item, "isEdit", bool);
      this.$set(item, "isDel", false);
      this.$nextTick(() => {
        this.$refs.inputQuestion[0].focus();
      });
    },
    del(item, bool) {
      this.QuestionList.forEach((item) => {
        this.$set(item, "isEdit", false);
        this.$set(item, "isDel", false);
      });
      this.$nextTick(() => {
        this.$set(item, "isDel", bool);
        this.$set(item, "isEdit", false);
      });
    },
    doIt(item, index) {
      if (item.isEdit) {
        this.blurQuestion(item, index);
        this.$set(item, "isDel", false);
        this.$set(item, "isEdit", false);
      }
      if (item.isDel) {
        this.delQuestion(item);
        this.$set(item, "isDel", false);
        this.$set(item, "isEdit", false);
      }
    },
    // 聊天重置
    handleCanceledit() {
      this.dialogVisible = false;
    },
    // 切换套餐
    changeMeal(item) {
      this.curMeal = item.commodityPackageId;
      this.commodityPackageName = item.commodityPackageName;
      this.number = new BigNumber(item.packageNum)
        .div(this.mealList.commodityMultiplier)
        .toNumber();
    },
    // 计数器
    handleChange() {
      if (this.companyValue != -1) {
        let flag = true;
        this.mealList.chatCommodityPackagesList.forEach((item) => {
          if (this.newNumber == item.packageNum) {
            this.curMeal = item.commodityPackageId;
            flag = false;
          } else if (this.newNumber != item.packageNum && flag) {
            this.curMeal = "";
          }
        });
      }
    },
    handleBlur() {
      if (this.number == undefined) {
        this.number = 1;
      }
      if (this.companyValue != -1) {
        let flag = true;
        this.mealList.chatCommodityPackagesList.forEach((item) => {
          if (this.newNumber == item.packageNum) {
            this.curMeal = item.commodityPackageId;
            flag = false;
          } else if (this.newNumber != item.packageNum && flag) {
            this.curMeal = "";
          }
        });
      }
    },
    // 订单详情
    handleEdit() {
      this.visible = false;
    },
    codeEdit() {
      this.codeVisible = false;
    },
    successEdit() {
      this.successVisible = false;
    },
    failEdit() {
      this.failVisible = false;
    },
    // 切换公司
    companyChange(val) {
      document.getElementById("text").value = "";
      this.isTextarea = false;
      this.List = [];
      this.titleId = null;
      this.userCompanyList.forEach((item) => {
        if (val == item.companyId) {
          this.companyName = item.companyName;
        }
      });
      this.getQuestionList();
      this.getUserVip();
    },
    // 聊天充值
    recharge() {
      this.dialogVisible = true;
      this.getChatCommodity();
      window.parent.postMessage(true, "*");
    },
    // 重置
    reset() {
      this.checked = false;
      this.curMeal = "";
      this.number = 1;
      this.radio = "0";
      this.QRCodeMask = false;
      this.commodityPackageName = "";
    },
    // 获取套餐信息
    getChatCommodity() {
      axios
        .get(this.prefixUrl + "/order/commodity/getChatCommodity", {
          params: {
            platformType: 2,
            commodityUserType: this.companyValue == -1 ? 0 : 1,
          },
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.mealList = res.data.data;
        })
        .catch((err) => {
          console.error("API调用异常：" + err);
        });
    },
    // 创建订单
    createOrder(data) {
      axios({
        url: this.prefixUrl + "/order/userOrder",
        method: "post",
        data: data,
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.orderNumber = res.data.msg;
          this.getUserOrder(res.data.msg);
        })
        .catch((err) => {
          console.error("API调用异常：" + err);
        });
    },
    // 获取订单信息
    getUserOrder(data) {
      axios({
        url: this.prefixUrl + "/order/userOrder/" + data,
        method: "get",
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.localCurrentTime = res.data.data.createTime;
        })
        .catch((err) => {
          console.error("API调用异常：" + err);
        });
    },
    // 支付接口
    payOrder(data) {
      axios({
        url: this.prefixUrl + "/order/userOrder/Payment",
        method: "get",
        params: data,
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.count = 0;
          const qrcode = document.getElementById("qrcode")
          this.qrText = this.radio == "0" ? res.data.data.code_url : res.data.data.qrCode
          qrcode.innerHTML = "";
          // 生成二维码图片
          QRCode.toCanvas(this.qrText, { width: 172, height: 172,errorCorrectionLevel: 'L' }, function (error, canvas) {
            console.log(canvas);
            qrcode.appendChild(canvas);
          });
          this.startPaymentPolling();
          var img = document.querySelectorAll("#qrcode canvas")
          img[0].title = "";
        })
        .catch((err) => {
          console.error("API调用异常：" + err);
        });
    },
    // 重新生成二维码
    regenerationCode() {
      this.QRCodeMask = false;
      let data = {
        orderNumber: this.orderNumber,
        payType: this.radio
      }
      this.payOrder(data)
      // const qrcode = document.getElementById("qrcode");
      // qrcode.innerHTML = "";
      // QRCode.toCanvas(this.qrText, { width: 172, height: 172,errorCorrectionLevel: 'L' }, function (error, canvas) {
      //   qrcode.appendChild(canvas);
      // });
      // this.$message.success("重新生成二维码成功");
      // this.startPaymentPolling();
      // var img = document.querySelectorAll("#qrcode canvas");
      // img[0].title = "";
    },
    // 支付通知
    payNotice() {
      axios({
        url: this.prefixUrl + "/order/userOrder/selectOrder",
        method: "get",
        params: {
          orderId: this.orderNumber,
          payType: this.radio,
        },
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (this.radio == "0") {
            if (res.data.data.trade_state == "SUCCESS") {
              clearInterval(this.interval);
              this.codeVisible = false;
              this.successVisible = true;
              this.getUserVip();
            } else if (res.data.data.trade_state == "PAYERROR") {
              clearInterval(this.interval);
              this.failVisible = true;
            }
          } else if (this.radio == "1") {
            if (res.data.data.trade_status == "TRADE_SUCCESS") {
              clearInterval(this.interval);
              this.codeVisible = false;
              this.successVisible = true;
              this.getUserVip();
            }
          }
          // 订单超时
          if (this.count >= 100) {
            clearInterval(this.interval);
            this.QRCodeMask = true;
            this.$message.warning("二维码已过期，请重新生成订单");
          }
          this.count += 1;
        })
        .catch((err) => {
          console.error("API调用异常：" + err);
        });
    },
    // 开始订单轮询定时器
    startPaymentPolling() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.payNotice();
      }, 3000);
    },
    // 充值提交
    rechargeSubmit() {
      this.visible = true;
      let data = {
        orderAmount: this.money, // 订单金额
        payAmount: this.money, // 支付金额
        platformType: 2, // 平台类型
        commodityPackageId: this.curMeal, // 选择的套餐id
        commodityPackageName: this.commodityPackageName, // 选择的套餐id
        packageNum: !!this.curMeal ? this.newNumber : "", // 套餐次数
        userId: this.userId, // 用户id
        buyTimes: this.number, // 购买次数
        productUnitPrice: this.mealList.commodityUnitPrice, // 产品单价
        companyId: this.companyValue != -1 ? this.companyValue : "", // 公司id
        productMultiplier: this.mealList.commodityMultiplier, // 商品倍率
        commodityName: this.mealList.commodityName, // 商品名称
        productId: this.mealList.commodityId, // 商品id
      };
      this.createOrder(data);
    },
    // 生成二维码支付提交
    paySubmit() {
      this.createCode(this.radio);
      let data = {
        orderNumber: this.orderNumber,
        payType: this.radio,
      };
      this.payOrder(data);
      this.codeVisible = true;
    },
    // 生成二维码
    createCode(val) {
      this.title = val == "0" ? "微信扫码支付" : "支付宝扫码支付";
    },
    // 重新支付
    rePayment() {
      this.createCode(this.radio);
      let data = {
        orderNumber: this.orderNumber,
        payType: this.radio,
      };
      this.payOrder(data);
      this.codeVisible = true;
      this.failVisible = false;
    },
    // 充值关闭回调
    handleClose() {
      if (
        !this.visible &&
        !this.codeVisible &&
        !this.dialogVisible &&
        !this.successVisible
      ) {
        setTimeout(() => {
          this.reset();
        }, 300);
        window.parent.postMessage(false, "*");
      }
    },
    // 订单详情支付关闭回调
    detailsClose() {
      if (
        !this.visible &&
        !this.codeVisible &&
        !this.dialogVisible &&
        !this.successVisible
      ) {
        setTimeout(() => {
          this.reset();
        }, 300);
        window.parent.postMessage(false, "*");
      }
    },
    // 二维码关闭回调
    codeClose() {
      clearInterval(this.interval)
      if (
        !this.visible &&
        !this.codeVisible &&
        !this.dialogVisible &&
        !this.successVisible
      ) {
        setTimeout(() => {
          this.reset();
        }, 300);
        window.parent.postMessage(false, "*");
      }
    },
    // 支付成功关闭回调
    successClose() {
      if (
        !this.visible &&
        !this.codeVisible &&
        !this.dialogVisible &&
        !this.successVisible
      ) {
        setTimeout(() => {
          this.reset();
        }, 300);
        window.parent.postMessage(false, "*");
      }
    },
  },
};
</script>

<style rel="stylesheet/scss" scoped lang="scss">
.box::v-deep {
  code{
    padding: 1em;
  }
  display: flex;
  height: 100vh;
  ul,
  ol {
    padding-left: 30px;
  }
  ul {
    list-style-type: none;
  }
  * {
    margin: 0;
    padding: 0;
  }
  .selectCompany .el-input__inner {
    border: none;
    padding: 0 30px 0 10px;
  }
  aside {
    width: 319px;
    height: 100%;
    float: left;
    font-size: 13px;
    border-right: 1px solid #e7e7e7;
  }

  .aside-top {
    position: relative;
    width: 264px;
    padding: 20px 28px 30px 28px;
  }

  .aside-top-btn {
    color: #0076f6;
    width: 262px;
    border: 1px solid #2d8bef;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
  }

  .aside-title {
    height: calc(100% - 280px);
    overflow: auto;
  }

  .aside-among {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aside-among-btn {
    border: 1px solid #2d8bef;
    cursor: pointer;
    z-index: 9999;
    margin-top: 20px;
  }

  .aside-among-btn:focus {
    outline: none;
  }

  .aside-bottom {
    width: 319px;
    height: 84px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #e7e7e7;
  }

  aside button {
    width: 262px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #0076f6;
    border-radius: 8px;
  }

  /* aside button:focus{
      outline: 0;
      color: #FFFFFF;
      background-color: #0076f6;
    } */
  article {
    width: calc(100% - 319px);
    height: 100%;
  }

  .article-top {
    height: 65%;
    overflow: auto;
    border-bottom: 1px solid #e7e7e7;
    background-color: #f6f6f6;
    padding: 30px;
  }

  .article-bottom {
    height: 25%;
  }

  .article-bottom-btn {
    width: 64px;
    height: 32px;
    color: #ffffff;
    background: #2d8bef;
    border-radius: 4px;
    border: none;
    position: absolute;
    bottom: 30px;
    right: 30px;
  }

  .article-bottom > span {
    position: absolute;
    bottom: 35px;
    right: 115px;
    color: #999999;
  }

  .article-bottom-btn:focus {
    outline: 0;
    color: #ffffff;
  }

  .btn-text {
    display: inline-block;
    width: 140px;
    text-align: left;
    float: left;
    height: 26px;
    line-height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .article-left {
    width: 100%;
    position: relative;
  }

  .article-left > .name-class {
    display: inline-block;
    width: 36px;
    position: absolute;
    background: #0076f6;
    border-radius: 6px;
    color: #ffffff;
    /* font-size: 12px; */
    text-align: center;
    /* padding-top: 10px;
      padding-bottom: 10px; */
  }

  .article-left > .text-class {
    max-width: 85%;
    margin: 0 0 20px 58px;
    display: inline-block;
    background: #d7e3ff;
    border-radius: 8px;
    padding: 11px 15px;
  }

  .article-right {
    width: 100%;
    /* position: relative; */
    float: right;
  }

  .article-right > .name-class {
    display: inline-block;
    float: right;
    width: 36px;
    background: #0076f6;
    border-radius: 6px;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .article-right > .text-class {
    /* width: calc(100% - 100px); */
    max-width: 65%;
    float: right;
    margin: 0 20px 20px 0;
    display: inline-block;
    background: #d7e3ff;
    border-radius: 8px;
    padding: 11px 20px;
  }

  #text {
    width: calc(100% - 350px);
    height: 75%;
    overflow: auto;
    border: none;
    resize: none;
    padding: 10px 0 0 10px;
    margin: 10px 0 0 10px;
    font-size: 16px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  #text:focus {
    outline: none;
    border-radius: 6px;
  }

  .input-question {
    width: 155px;
    border: none;
    border: 1px solid #e7e7e7;
    padding: 5px;
    margin-right: 50px;
    border-radius: 4px;
    /* border-color: #4D7FF1; */
  }

  .input-question:focus {
    outline: none;
  }

  /* 按钮组 */
  .title-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* visibility: hidden; */
    width: 40px;
    margin: -37px -205px 0 0;
  }

  .title-btn > .icon {
    margin-left: 4px;
    border-left: none;
    border-right: none;
    border-radius: 0;
    background-color: transparent;
  }

  /* dialog 样式 */
  .el-dialog__title {
    font-size: 16px;
    font-weight: 400;
    font-family: Microsoft YaHei;
    color: #333333;
  }
  .dialog-footer{
    padding: 10px 20px 20px;
    .el-button{
      width: 60px;
      height: 26px;
    }
  }
  .el-checkbox__label{
    padding-left: 10px;
  }
  .el-dialog__header {
    background-color: #e7e7e7;
    padding: 13px 0 14px 21px;
  }

  .el-dialog__body {
    padding: 20px 30px;
  }
  .el-dialog{
    margin: 0 auto;
  }
  .sus-dialog .el-dialog__header {
    display: none;
  }
  .sus-dialog .el-dialog__body {
    padding-top: 52px;
  }
  .code-dialog .el-dialog__body {
    text-align: center;
  }
  .code-dialog .el-dialog__header {
    text-align: center;
    padding: 13px 21px 14px 21px;
  }
  .payment-dialog-QRCode-box {
    position: relative;
    display: inline-block;
    width: 172px;
    height: 172px;
  }
  .payment-dialog-QRCode-box-mask {
    position: absolute;
    background-color: #333;
    opacity: 0.7;
    display: inline-block;
    width: 430px;
    height: 251px;
    line-height: 251px;
    font-weight: 600;
    font-size: 14px;
    left: -129px;
    top: -20px;
  }
}
</style>
