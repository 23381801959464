<template>
  <div id="app">
    <xiaobai></xiaobai>
  </div>
</template>

<script>
import xiaobai from './components/xiaobai'
export default {
  name: 'App',
  components: {
    xiaobai
  }
}
</script>

<style>
#app {

}
body{
  margin: 0;
}
</style>
